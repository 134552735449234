import React, { useContext } from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

// COMPONENTS
import { Context } from '../Context'

const Team = () => {
    const [product] = useContext(Context);

    return (
        <div className="team">
            <div className="team-center">
                {
                    product.map(item => {
                        const { _id, icon, title, text } = item;
                        return (
                            <article key={_id} className="team-card" data-aos="zoom-in" data-aos-once="true">
                                <Link to={`/details/${_id}/`}>
                                    <div className="team-image">
                                        {icon}
                                    </div>
                                </Link>
                                <h4>{title}</h4>
                                <p>{text}</p>
                            </article>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Team
