import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

const Own = () => {
    return (
        <div className="own">
            <div className="own-center">
                <div className="own-left">
                    <div className="own-left-center">
                        <div className="underline" data-aos="slide-righ" data-aos-once="true"></div>
                        <h3 data-aos="zoom-ou" data-aos-once="true">We help <span style={{color:"var(--main)"}}>businesses</span> across the world</h3>
                        <p data-aos="fade-u" data-aos-once="true">We apply intense discipline to every aspect of managing your transportation and logistics needs while ensuring cost efficiency in delivering superior service</p>
                        <Link to="/investment">
                            <button data-aos="fade-dow" data-aos-once="true">Get Started</button>
                        </Link>
                    </div> 
                </div>

                <div className="own-right">
                    <div className="own-right-center" data-aos="fade-i" data-aos-once="true">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Own
