import React from 'react'

// PACKAGES
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// COMPONENTS

// IMAGES
const shake = "https://res.cloudinary.com/devsource/image/upload/v1623340006/Lighthill/3_u51mbt.jpg"


const Clients = () => {
    
    return (
        <div className="client">
            <div className="client-left">
                <div className="client-left-img" data-aos="flip-righ" data-aos-once="true">
                    <img src={shake} alt="shake" />
                </div>
            </div>

            <div className="client-right">
                <h2 data-aos="fade-dow" data-aos-once="true">Why are we trusted by over 5000 clients?</h2>
                <p data-aos="fade-u" data-aos-once="true">To provide excellent and comprehensive financial services in a friendly environment using qualified and experience personnel with appropriate technology delighting our stakeholders.</p>
                <div className="client-list" data-aos="zoom-ou" data-aos-once="true">
                    <ul>
                        <li><FaCheckCircle className="check" />Premium services that goes beyond your expectation</li>
                        <li><FaCheckCircle className="check" />Providing the best support among all vendors</li>
                        <li><FaCheckCircle className="check" />Best deals available in the market</li>
                        <li><FaCheckCircle className="check" />Timely deliverable.</li>
                    </ul>
                </div>
                <Link to="/contact">
                    <button data-aos="fade-u" data-aos-once="true">GET IN TOUCH</button>
                </Link>
            </div>
        </div>
    )
}

export default Clients
