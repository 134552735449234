import React from 'react'

// COMPONENTS
import Achievement from '../components/Achievement'
import Callback from '../components/Callback'
import ContactUs from '../components/ContactUs'
import Count from '../components/Count'
import Hero2 from '../components/Hero2'
import Request from '../components/Request'
import SuccessStories from '../components/SuccessStories'
import Five from '../subsidiaries/components/Five'
import Four from '../subsidiaries/components/Four'
import One from '../subsidiaries/components/One'
import Three from '../subsidiaries/components/Three'
import Two from '../subsidiaries/components/Two'
import SolutionHero from '../subsidiaries/SolutionHero'



import new1 from "../assets/new1.webp";
import ship from "../assets/ship.webp";
import plane from "../assets/plane.webp";

import t1 from '../assets/money.svg'
import t2 from '../assets/fund.svg'
import t3 from '../assets/consultant.svg'
import t4 from '../assets/planning.svg'
import SEO from '../components/SEO'



// ONE
const text1="Financial Planning" 
const text2="Strong Partnership" 
const text3="Customer Insights"

// TWO
const texts1 = "We provide financial planning";
const texts2 = "The team at HEIGHTHILLS are more than real estate agents looking for real estate listings. We are a dedicated team of truly passionate, property professionals who understand our clients’ needs and wants. When making property decisions that impact your family now and in the future, you need a HEIGHTHILLS partner. We understand that your home is an important part of your life, it houses your family, it’s where memories are made and it provides for your future. "

// THREE
const heading="Why We are the Best";
const test =  `We actively source, capitalize and manage real estate investment opportunities in primary markets in the Lagos and Abuja. We evaluate opportunities from individual properties to complex portfolios with a strategic focus on real estate offering underlying value-creation opportunities. Our experience spans almost all asset classes, including office, retail, residential, industrial and hotels. We take a hands-on approach to acquiring and managing real estate, with considerable expertise in value-add strategies requiring leasing execution, improved management, building repositioning, redevelopment and distressed situations.`
const sub1 = "Business Partnership"
const sub2 = "Marketing Strategy"
const sub3 = "Risk Assessment"
const sub4 = "Capital Gains"

// FOUR
const fourHeading = "HEIGHTHILL SOLUTION";
const fourText1 = "Service and integrity supported by commercial expertise is where HEIGHTHILLS SOLUTIONS stands apart. A depth of local knowledge and eye for opportunities, we are your property partner."
const fourText2 = "Our team will always provide you with honest, carefully considered advice. We will ensure you are in the best possible position to make clear decisions. Your success is our principal driver.";
const fourText3 = "Luxury real estate is our passion and it demands our focus. We strive to provide a high level of personalized service, whether you are a vendor or a potential purchaser."
const fourImage = "https://res.cloudinary.com/devsource/image/upload/v1622075584/Lighthill/Differences-between-leases-and-rental-agreements-FB-1200x700-compressed_oux02e.jpg"

// FIVE
const fiveHeading = "HEIGHTHILL SOLUTION";
const fiveText = `For over eight years HEIGHTHILLS SOLUTIONS has been building a wealth of experience and knowledge in the high-value, luxury property market. HS specializes in the finest coastal, lifestyle, luxury and executive real estate in Lagos Nigeria
The success of the whole process is based on the trust the clients place in us. We believe that one of the key points to our success is to understand the client’s wishes.
Our aim, as a company, is to have a proactive and productive team and close successful real estate deals, but above all, have a client that is pleased with their decision.
Customers have come to expect only the best from our team. We deliver this by understanding their goals and then adopting them as our own.
We're committed to providing the gold standard in service.
`
const fiveImage = "https://res.cloudinary.com/devsource/image/upload/v1623339477/Lighthill/feature-1_neghw8.jpg"

const Currency_Trading = () => {
    return (
        <div>
            <SEO title="Solution"/>
            <SolutionHero/>
            <One img1={new1} img2={ship} img3={plane} text1={text1} text2={text2} text3={text3}/>
            <Two texts1={texts1} texts2={texts2}/>
            <Three t1={t1} t2={t2} t3={t3} t4={t4} heading={heading} test={test} sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4}/>
            <Count/>
            <Four fourHeading={fourHeading} fourText1={fourText1} fourText2={fourText2} fourText3={fourText3} fourImage={fourImage}/>
            <SuccessStories/>
            <Five fiveHeading={fiveHeading} fiveText={fiveText} fiveImage={fiveImage}/>
            <ContactUs/>
            <Hero2/>
            <Achievement/>
            <Request />
            <Callback />
        </div>
    )
}

export default Currency_Trading
