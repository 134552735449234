import React, { createContext, useState, useEffect } from 'react'

import team from './constants/Team'

export const Context = createContext()

export const TeamProvider = ({ children }) => {
    //  CREATING THE STATE
    const [product, setProduct] = useState([]);


    useEffect(() => {
        setTeam();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // SETTING THE TEAM
    const setTeam = () => {
        let tempTeam = [];
        team.forEach((item) => {
            const singleTeam = { ...item };
            tempTeam = [...tempTeam, singleTeam];
        })
        setProduct(tempTeam)
    }



    return (
        <Context.Provider value={[product, setProduct]}>
            {children}
        </Context.Provider>
    )
}