import React from 'react'
import {Helmet} from 'react-helmet'
import image from '../assets/icon.jpg'
import Favicon from '../assets/icon.jpg'

const SEO = ({title}) => {
    return (
        <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | Lighthill Group`}>
            <meta name="image" content={image} />
            <meta name="description" content="Lighthill Investment Company is a leading investment and financial company that provides a wide range of investment solutions, forex trading services, and real estate management. Our platforms are specifically designed to meet the needs of individual, corporate and institutional clients." />
            <link rel="shortcut icon" href={Favicon}/>
        </Helmet>
    )
}

export default SEO
