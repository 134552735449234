import React from 'react'
// import { FaPlus } from 'react-icons/fa'

const Four = ({fourHeading,fourText1,fourText2,fourText3,fourImage}) => {
    return (
        <div className="four">
            <div className="four-center">
                <div className="four-center-left" data-aos="fade-right" data-aos-once="true">
                    <img src={fourImage} alt="fourImage"/>
                </div>

                <div className="four-center-right">
                    <div className="four-box">
                        <h2 data-aos="fade-down" data-aos-once="true">{fourHeading}</h2>
                        <div className="box-div" data-aos="fade-up" data-aos-once="true">
                            {/* <FaPlus className="plus"/> */}
                            <p>{fourText1}</p>
                        </div>

                        <div className="box-div" data-aos="fade-up" data-aos-once="true">
                            {/* <FaPlus className="plus"/> */}
                            <p>{fourText2}</p>
                        </div>

                        <div className="box-div" data-aos="fade-up" data-aos-once="true">
                            {/* <FaPlus className="plus"/> */}
                            <p>{fourText3}</p>
                        </div>
                        
                       
                    </div>
                </div>    
            </div>
        </div>
    )
}

export default Four
