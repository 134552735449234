import React from 'react'

import path3 from '../assets/Path1.svg'
import path2 from '../assets/Path2.svg'
import path1 from '../assets/Path3.svg'

const ContactUs = () => {
    return (
        <section className="contact bg-grey">
            <h2 data-aos="fade-down" data-aos-once="true">Contact Us</h2>
            <div className="contact-center contacts">
                <article className="contact-div" data-aos="zoom-out" data-aos-once="true">
                    <div className="icon-contact green">
                        <img src={path1} alt="path1" />
                    </div>
                    <div className="text-contact">
                        <p>Send us an email or reach out to us on any of our social media pages for help with a current product or service or refer to FAQs</p>
                    </div>
                </article>

                <article className="contact-div" data-aos="zoom-out" data-aos-once="true">
                    <div className="icon-contact blue">
                        <img src={path2} alt="path2" />
                    </div>
                    <div className="text-contact">
                        <p>Call us to speak to a member of our team</p>
                        <h4>+234001234566</h4>
                        <h4>+234001234566</h4>
                    </div>
                </article>

                <article className="contact-div" data-aos="zoom-out" data-aos-once="true">
                    <div className="icon-contact contact-yellow">
                        <img src={path3} alt="path3" />
                    </div>
                    <div className="text-contact">
                        <p>Get in touch with us today for media resources</p>
                        <h4>info@lighthillng.com</h4>
                    </div>
                </article>
            </div>
        </section>
    )
}

export default ContactUs
