import React from "react"
import services from "../constants/AboutCard"


const AboutCard = () => {
    return (
        <section className="about-card bg-grey">
            <h2 data-aos="fade-down" data-aos-once="true">We Offer the Best Services</h2>
            <div className="about-card-center why-center">
                {services.map((service) => {
                    const { id, icon, title, text } = service;
                    return (
                        <article key={id} className="about-card-div" data-aos="zoom-out" data-aos-once="true">
                            <div className="about-card-icon-box">
                                {icon}
                            </div>
                            <div className="text-box">
                                <h4>{title}</h4>
                                <p>{text}</p>
                            </div>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}

export default AboutCard
