const Data = [
    {
        id: 1,
        title: 'Who We Are',
        description:
            'Lighthill Investment Company is a leading investment and financial company that provides a wide range of investment solutions, forex trading services, and real estate management.  Our platforms are specifically designed to meet the needs of individual, corporate and institutional clients.',
    },
    {
        id: 2,
        title: 'Our Passion',
        description:
            'With passion, respect and dignity, we relentlessly seek innovative solutions that will deliver great value to our customers at highly competitive prices while ensuring optimal value for our stakeholders.',
    },
    {
        id: 3,
        title: 'What We Do',
        description:
            'Lighthill Investment Company Ltd is a multiple-ranged products and services company, with its subsidiary companies involved in the following businesses – Currency trading & investment, Bureau De Change energy, logistics services ,Real Estate and Consumer loan & lease services. ',
    },
    {
        id: 4,
        title: 'Our Focus',
        description:
            'Our focus is Consumer Lending and Asset Leasing. This enables us to fulfil our primary objective of delivering competitive investment performance, along with the highest levels of customer services.',
    },
    {
        id: 5,
        title: 'Our Services',
        description:
            `We are strategically partnered with legally and reliable agents abroad for swift and
            immediate fund remittance. We manage personal -to- personal remittance, personal -to-
            business and business -to- business form of remittance.
            `,
    },
]
export default Data