import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

const Plans = () => {
    return (
        <div className="plans">
            <div className="plans-center">
                <div className="plans-left">
                    <div className="plans-left-center">
                        <div className="underline" data-aos="fade-righ" data-aos-once="true"></div>
                        <h3 data-aos="zoom-in" data-aos-once="true">We <span style={{color:"var(--main)"}}>Deliver</span> our promises to our <span style={{color:"var(--main)"}}>Customers</span></h3>
                          
                        <p data-aos="zoom-ou" data-aos-once="true">Service and integrity supported by commercial expertise is where HEIGHTHILLS SOLUTIONS stands apart. A depth of local knowledge and eye for opportunities, we are your property partner.</p>
                        <Link to="/contact">
                            <button data-aos="slide-u" data-aos-once="true">REACH OUT</button>
                        </Link>
                    </div>
                </div>

                <div className="plans-right">
                    <div className="plans-right-center" data-aos="fade-dow" data-aos-once="true">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
