import React from 'react'
import { useHistory } from 'react-router'

// PACKAGES

const ImportExportHero = () => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="investmentHero-hero">
            <div className="investmentHero-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>IMPORT AND EXPORT</h1>
                <p>
                    Our company with her available resources and partnerships with brokers clientele, no
                    doubt provides prospective customers with fast, efficient , reliable and competitive
                    Value Added Services.
                </p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>

        </div>
    )
}

export default ImportExportHero
