import React from 'react'

// COMPONENTS
import Achievement from '../components/Achievement'
import Callback from '../components/Callback'
import ContactUs from '../components/ContactUs'
import Count from '../components/Count'
import Hero2 from '../components/Hero2'
import Request from '../components/Request'
import SuccessStories from '../components/SuccessStories'
import Five from '../subsidiaries/components/Five'
import Four from '../subsidiaries/components/Four'
import One from '../subsidiaries/components/One'
import Three from '../subsidiaries/components/Three'
import Two from '../subsidiaries/components/Two'
import LeasingHero from '../subsidiaries/LeasingHero'



import new1 from "../assets/new1.webp";
import ship from "../assets/ship.webp";
import plane from "../assets/plane.webp";

import t1 from '../assets/money.svg'
import t2 from '../assets/fund.svg'
import t3 from '../assets/consultant.svg'
import t4 from '../assets/planning.svg'
import SEO from '../components/SEO'



// ONE
const text1="Leasing Advisory" 
const text2="Space Enablement" 
const text3="Strategy & Consulting"

// TWO
const texts1 = "Small Business Loans For a Daily Expenses";
const texts2 = "In the face of dire economic straits occasioned by the worst recession experienced in the chequered history of the country, a situation that gave rise to high inflation rate  leaving many lacking the ability to afford their daily needs including household items. There is the urgent need for a window to address the hapless situations of the people especially those in paid employment."

// THREE
const heading="Why We are the Best";
const test =  `Insighthills Leasing Company Limited (ILCL) is a financial services company that provides a wide range of credit solutions. Our platforms are specifically designed to meet the needs of individual, corporate and institutional customers.
                Our focus is Consumer Lending and Asset Leasing. This enables us to fulfil our primary objective of delivering competitive investment performance, along with the highest levels of customer services.
                `
const sub1 = "SECURE PROCESS"
const sub2 = "NO PAYMENT PENALTY"
const sub3 = "QUICK AND EASY"
const sub4 = "LOWER RATES"

// FOUR
const fourHeading = "INSIGHTHILLS LEASING";
const fourText1 = `We work as one: And because we do, we are unified and focused in helping our customers.`
const fourText2 = `Developing more holistic and long-lasting customer relationships as we continue to build and integrate our global wealth and asset management businesses.`;
const fourText3 = `We anticipate each customer’s unique needs to deliver innovative, comprehensive, and thoughtful advice and solutions`
const fourImage = "https://res.cloudinary.com/devsource/image/upload/v1623329772/Lighthill/advisor_cgvfp6.jpg"


// FIVE
const fiveHeading = "INSIGHTHILLS LEASING";
const fiveText = `We strive to create long-term value for our stakeholders through strong business fundamentals, consistent with our mission guided by our vision and directed by our company's core values.
                    Our top priority, every minute of the day, is to serve our customers the very best we can. To do this, we rely on the quality and commitment of our people, and on our ability to cooperate and generate synergies
                    We are known for delivering on our promises to our customers, and are recognized as a trusted brand and one of the world’s most admired companies.
                    `
const fiveImage = "https://res.cloudinary.com/devsource/image/upload/v1623330247/Lighthill/banner1_yixxav.png"

const Property = () => {
    return (
        <div>
            <SEO title="Leasing"/>
            <LeasingHero/>
            <One img1={new1} img2={ship} img3={plane} text1={text1} text2={text2} text3={text3}/>
            <Two texts1={texts1} texts2={texts2}/>
            <Three t1={t1} t2={t2} t3={t3} t4={t4} heading={heading} test={test} sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4}/>
            <Count/>
            <Four fourHeading={fourHeading} fourText1={fourText1} fourText2={fourText2} fourText3={fourText3} fourImage={fourImage}/>
            <SuccessStories/>
            <Five fiveHeading={fiveHeading} fiveText={fiveText} fiveImage={fiveImage}/>
            <ContactUs/>
            <Hero2/>
            <Achievement/>
            <Request />
            <Callback />
        </div>
    )
}

export default Property
