import React from 'react'


const AboutHero = ({ title, text }) => {
    return (
        <div className="gallery-hero">
            <div className="gallery-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>Gallery</h1>
                <p>Our Business offers varying currency exchange with swift seamless payments to all our
                clients, we are securely and safely dependable for your cash purchase and sales of forex.
                </p>
            </div>
        </div>
    )
}

export default AboutHero
