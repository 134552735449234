import React, { useState, useEffect } from 'react';
import { HiArrowCircleLeft, HiArrowCircleRight } from 'react-icons/hi';
import { FaQuoteRight } from 'react-icons/fa';
import data from '../constants/people';


function Testimonial() {
    const [people] = useState(data);
    const [index, setIndex] = React.useState(0);

    useEffect(() => {
        const lastIndex = people.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, people]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 5000);
        return () => {
            clearInterval(slider);
        };
    }, [index]);

    return (
        <section className="testimonial-section">
            <div className="testimonial-title">
                <h2 data-aos="fade-up" data-aos-once="true">Client Testimonials</h2>
            </div>

            <div className="testimonial-center">
                {people.map((person, personIndex) => {
                    const { id, name, title, quote } = person;

                    let position = 'nextSlide';
                    if (personIndex === index) {
                        position = 'activeSlide';
                    }
                    if (
                        personIndex === index - 1 ||
                        (index === 0 && personIndex === people.length - 1)
                    ) {
                        position = 'lastSlide';
                    }

                    return (
                        <main className={position} key={id}>
                            {/* <img src={image} alt={name} className="person-img" /> */}
                            <h4>{name}</h4>
                            <p className="test-title">{title}</p>
                            <p className="test-text">{quote}</p>
                            <FaQuoteRight className="icon" />
                        </main> 
                    );
                })}
                <button className="pre" onClick={() => setIndex(index - 1)}>
                    <HiArrowCircleLeft />
                </button>
                <button className="nex" onClick={() => setIndex(index + 1)}>
                    <HiArrowCircleRight />
                </button>
            </div>
        </section>
    );
}

export default Testimonial;