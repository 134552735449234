import React from 'react'
import services from '../constants/SubComponent'

const SubComponent = () => {
    return (
        <div className="sub_component">
            <h2 data-aos="slide-down" data-aos-once="true">Subsidiaries of Lighthill Group</h2>
            <div className="sub-center">
                {
                    services.map(service => {
                        const { id, icon, title, text } = service
                        return (
                            <div key={id} className="sub-card" data-aos="flip-left" data-aos-once="true">
                                <div className="card-header">
                                    <div className="card-left">
                                        {icon}
                                    </div>
                                    <div className="card-right">
                                        {title}
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <p>{text}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SubComponent
