import React from 'react'

// PACKAGES

const ContactHero = ({ title, text }) => {
    return (
        <div className="contacts-hero">
            <div className="contacts-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>Contact Us</h1>
                <p>Our team will always provide you with honest, carefully considered advice. We will ensure you are in the best possible position to make clear decisions. </p>
            </div>

        </div>
    )
}

export default ContactHero
