import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

// IMAGES


const Three = ({t1,t2,t3,t4,heading,test, sub1, sub2, sub3, sub4}) => {
    return (
        <div className="three">
           <div className="three-left">
               <div className="three-left-center">
                   <h1 data-aos="fade-down" data-aos-once="true">{heading}</h1>
                   <p data-aos="slid-up" data-aos-once="true">{test}</p>
                   <Link to="/contact">
                        <button data-aos="slide-down" data-aos-once="true">GET INTOUCH</button>
                   </Link>
               </div>
           </div>

           <div className="three-right">
               <div className="three-right-center">
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                       <img src={t1} alt="t1"/>
                       <h3>{sub1}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t2} alt="t2"/>
                        <h3>{sub2}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t3} alt="t3"/>
                        <h3>{sub3}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t4} alt="t4"/>
                        <h3>{sub4}</h3>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Three
