import React from 'react'
import { useHistory } from 'react-router'

// PACKAGES

const BdcHero = () => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="bdcHero-hero">
            <div className="bdcHero-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>INSIGHTHILLS BDC</h1>
                <p>We offers a diverse and esteemed clientele foreign exchange services at competitive rates using qualified manpower while operating within the stipulated regulatory framework.</p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>

        </div>
    )
}

export default BdcHero
