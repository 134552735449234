import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'

const Talk = () => {
    return (
        <div className="talk">
            <div className="talk-text">
                <h3 data-aos="fade-down" data-aos-once="true">Wanna Talk to us <span className="services-icon">?</span></h3>
                <p data-aos="fade-up" data-aos-once="true">Please feel free to contact us. We’re super happy to talk to you. Feel free to ask anything.</p>
            </div>

            <div className="talk-btn" data-aos="zoom-in" data-aos-once="true">
                <Link to="/contact">
                    <button>
                        Contact Us
                        <FaArrowRight className="talk-icon service-icon"/>    
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Talk