import React from 'react'
import ContactUs from '../components/ContactUs'
import Request from '../components/Request'
import Callback from '../components/Callback'
import ContactHero from '../components/ContactHero'
import Care from '../components/Care'
import SEO from '../components/SEO'

const Contact = () => {
    return (
        <div>
            <SEO title="Contact"/>
            <ContactHero/>
            <Care/>
            <ContactUs/>
            <Request/>
            <Callback/> 
        </div> 
    )
}

export default Contact
