import React from "react"
import services from "../constants/why"


const Services = () => {
    return (
        <section className="why bg-grey">
            <h2 data-aos="fade-down" data-aos-once="true">Why should you choose us?</h2>
            <div className="div-center why-center">
                {services.map((service) => {
                    const { id, icon, title, text } = service;
                    return (
                        <article key={id} className="why-div">
                            <div className="icon-box" data-aos="flip-down" data-aos-once="true">
                                {icon}
                            </div>
                            <div className="text-box">
                                <h4 data-aos="slide-up" data-aos-once="true">{title}</h4>
                                <p data-aos="zoom-out" data-aos-once="true">{text}</p>
                            </div>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}

export default Services
