import React from 'react'

// IMAGES

import chronometer from '../assets/chronometer.svg'
import headphones from '../assets/headphones.svg'
import premium from '../assets/premium.svg'
const driven = "https://res.cloudinary.com/devsource/image/upload/v1623855457/Lighthill/one_xptxnr.jpg"

const Driven = () => {


    return ( 
        <div className="driven">
            <div className="driven-top">
                <div className="driven-top-left">
                    <h1 data-aos="fade-dow" data-aos-once="true">We are driven by our core values.</h1>
                    <p data-aos="fade-i" data-aos-once="true">From the birth of LightHill, We have excelled in the following areas:</p>
                    <div className="driven-box">
                        <div className="driven-boxs" data-aos="flip-u" data-aos-once="true">
                            <img src={premium} alt="driven-icons" />
                            <small>Premium Services</small>
                        </div>
                        <div className="driven-boxs" data-aos="flip-u" data-aos-once="true">
                            <img src={headphones} alt="driven-icons" />
                            <small>Best Support</small>
                        </div>
                        <div className="driven-boxs" data-aos="flip-u" data-aos-once="true">
                            <img src={chronometer} alt="driven-icons" />
                            <small>Timely Delivery</small>
                        </div>
                    </div>
                </div>

                <div className="driven-top-right">    
                    <div className="driven-top-right-center" data-aos="fade-down" data-aos-once="true">
                        <img src={driven} alt="driven"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Driven
