const people = [
    {
        id: 1,
        name: 'Lorance Joy',
        title: 'Director, Axera Nigeria Ltd',
        quote:`Being a merchandise mover, time is always of the essence and that's why we have found our involvement with Lighthill Logistics very valuable.`,
    },
    {
        id: 2,
        name: 'Adebunmi Sunday Ogunleye',
        title: 'COO Dia Multiglobal Concepts Limited',
        quote:
            'INSIGHTHILLS Leasing have been supporting my business with very fast and interest friendly asset leasing solutions for years now. And I can say I have no regrets.',
    },
    {
        id: 3,
        name: 'Michael Ayeni',
        title: 'MD Pasture Solid Minerals',
        quote:
            "I have always observed excellent customer service and engagement while doing business with this company. It's been a great experience.",
    },
    {
        id: 4,
        name: 'SUD',
        title: 'MD Gslogistics ',
        quote:
            'Lighthill is a Company I can leave my money with and go sleep peacefully, that’s how much i trust them. I have been in business with them for over 5years and never had a reason to regret it',
    },
    {
        id: 5,
        name: 'Mr Bamidele',
        title: 'MD Perspective',
        quote:
            'I have always experienced excellent service dealing with Lighthill and their swift response is top notch .',
    },
];

export default people;