import React from "react";

// PACKAGES
import { Link } from "react-router-dom";
import HeroSlider, { Slide, MenuNav, OverlayContainer } from "hero-slider";
import Typewriter from 'typewriter-effect'



// Images
const kyoto = "https://res.cloudinary.com/devsource/image/upload/v1621425777/pic2_nqsilv.jpg";
const tenryuJiTemple = "https://res.cloudinary.com/devsource/image/upload/v1622075584/Lighthill/Differences-between-leases-and-rental-agreements-FB-1200x700-compressed_oux02e.jpg";
const hakone = "https://res.cloudinary.com/devsource/image/upload/v1621553349/Lighthill/unnamed_hhyzfh.jpg";
const byodoInTemple = "https://res.cloudinary.com/devsource/image/upload/v1622075564/Lighthill/Netflix-of-ethical-investment_h2tgyf.jpg";

const app = () => {
  return (
    <HeroSlider
      slidingAnimation="left_to_right"
      orientation="horizontal"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={nextSlide => console.log("onChange", nextSlide)}
      onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "#000"
      }}
      settings={{
        slidingDuration: 400,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 3000,
        height: "100vh"
      }}
    >
      {/* <Navbar /> */}
      <OverlayContainer className="overlay-container">
        <div className="wrapper">
          <div className="subtitle">
            <Typewriter
              options={{
                strings: [`Nigeria's #1 Currency Trading Company`],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
          {/* <div className="subtitle">Nigeria's <span>#1</span> Currency Trading Company</div> */}
          <div className="title"><span>We Help</span> Grow Investments</div>
          <div className="hero_text">We relentlessly seek innovative solutions that delivers great value to our customers at higly competitive
          prices while ensuring optimal value for our stakeholders
            </div>
          <Link to="/contact">
            <button className="hero_btn from-top">Learn More</button>
          </Link>
        </div>
      </OverlayContainer>

      <Slide
        shouldRenderMask
        navDescription="ATTENTIVENESS"
        background={{
          backgroundColor: "yellow",
          backgroundImage: kyoto,
        }}
      />

      <Slide
        shouldRenderMask
        navDescription="TRUST"
        background={{
          backgroundColor: "#8A8A8A",
          backgroundImage: tenryuJiTemple
        }}
      />

      <Slide
        shouldRenderMask
        navDescription="COMMUNICATION"
        background={{
          backgroundColor: "#EA2329",
          backgroundImage: hakone,
        }}
      />

      <Slide
        shouldRenderMask
        navDescription="INTEGRITY"
        background={{
          backgroundColor: "#2D7791",
          backgroundImage: byodoInTemple
        }}
      />

      <MenuNav style={{ color: 'blue' }} />
    </HeroSlider>
  );
};

export default app;

// we adhere to a fiduciary standard which places their needs ahead of our own.
// - We give our client's unbiased information to help them make smart decisions.
// Our client's trust us because we have their best interest at heart.
// Attentiveness - Along with our financial knowledge we are always attentive to our client's needs.