import React from 'react'

// COMPONENTS
import Hero1 from '../components/Hero1'
import Why from '../components/Why'
import Trusted from '../components/Trusted'
import Hero2 from '../components/Hero2'
import SubComponent from '../components/SubComponent'
import Clients from '../components/Clients'
import Testimonial from '../components/Testimonial'
import SuccessStories from '../components/SuccessStories'
import ContactUs from '../components/ContactUs'
import Request from '../components/Request'
import Callback from '../components/Callback'
import Count from '../components/Count'
import Strategies from '../components/Strategies'
import Driven from '../components/Driven'
import Talk from '../components/Talk'
import Own from '../components/Own'
import Plans from '../components/Plans'
import Partner from '../components/Partner'
import Fast from '../components/Fast'
import SEO from '../components/SEO'


const Home = () => {
    return (
        <div>
            <SEO title="Home" />
            <Hero1 />
            <Trusted />
            <Count />
            <Why />  
            <SubComponent /> 
            <Clients />
            <Plans/> 
            <Strategies />
            <Partner/>
            <Fast/>
            <Talk/> 
            <Driven />
            <Own/> 
            <Testimonial />
            <SuccessStories />
            <ContactUs />
            <Hero2 /> 
            <Request />
            <Callback />
        </div> 
    )
}

export default Home
