import React from 'react'

// COMPONENTS
import Achievement from '../components/Achievement'
import ContactUs from '../components/ContactUs'
import Hero2 from '../components/Hero2'
import SuccessStories from '../components/SuccessStories'
import One from '../subsidiaries/components/One'
import Two from '../subsidiaries/components/Two'
import Three from '../subsidiaries/components/Three'
import Four from '../subsidiaries/components/Four'
import Five from '../subsidiaries/components/Five'
import LogisticHero from '../subsidiaries/LogisticHero'
import Callback from '../components/Callback'
import Request from '../components/Request'
import Count from '../components/Count'



import new1 from "../assets/new1.webp";
import ship from "../assets/ship.webp";
import plane from "../assets/plane.webp";

import t1 from '../assets/t1.webp'
import t2 from '../assets/t2.webp'
import t3 from '../assets/t3.webp'
import t4 from '../assets/t4.webp'
import SEO from '../components/SEO'



// ONE
const text1="Warehousing Services" 
const text2="Ocean Freight Services" 
const text3="Air Flight Services"

// TWO
const texts1 = "Global Logistics Solutions";
const texts2 = "Welcome to Lighthill Logistics Company Ltd, Our tagline ‘Driven by You’, is our commitment to our clients – we drive towards supply chain excellence, in response to the needs of our clients. Lighthill Logistics is a company with interest in transports and logistics. The company boasts of a proven antecedent in offering service flexibility, reliability, accuracy, security and speed. Our team of seasoned professionals work directly with designated agents to provide safe local transport."

// THREE
const heading="Why We are the Best";
const test = "We pride ourselves on providing the best transport and shipping services available allover the world. Our skilled personnel, utilising communications, tracking and processing software, combined with decades of experience! Through integrated supply chain solutions, Equita drives sustainable competitive advantages to some of Australia’s largest companies."
const sub1 = "Safe Packing"
const sub2 = "In Time Delivery"
const sub3 = "Ship Everywhere"
const sub4 = "Fastets Shipping"

// FOUR
const fourHeading = "LIGHTHILL LOGISTICS";
const fourText1 = "We facilitate deliveries by offering pick-up and drop-off services for small to medium sized packages within and outside Lagos state for both businesses and individuals"
const fourText2 = "We apply intense discipline to every aspect of managing your transportation and logistics needs while ensuring cost efficiency in delivering superior service";
const fourText3 = "We facilitate commuting within the Lagos metropolis while also easing transportation hassles in the interior part of Lagos and the whole Nigeria."
const fourImage = "https://res.cloudinary.com/devsource/image/upload/v1622918673/Lighthill/smartmockups_kpk2us0u_dfb2mq.png"

// FIVE
const fiveHeading = "LIGHTHILL LOGISTICS";
const fiveText = "What sets us apart from the rest is our highly trained and motivated staff, Flexibility and fast decision making. Our clients trust LLC to supply the best flexible service they need now and going forward; we continually adapt our services to meet their ever-changing circumstances. It’s not only about delivering the best service, At Lighthill Logistics Limited Company, we also help clients to optimize their supply chains by developing and implementing customized transportation solutions that best fit their needs."
const fiveImage = "https://res.cloudinary.com/devsource/image/upload/v1623341362/Lighthill/forklift_rifttm.png"


const Logistics = () => {
    return (
        <div>
            <SEO title="Logistics"/>
            <LogisticHero/>
            <One img1={new1} img2={ship} img3={plane} text1={text1} text2={text2} text3={text3}/>
            <Two texts1={texts1} texts2={texts2}/>
            <Three t1={t1} t2={t2} t3={t3} t4={t4} heading={heading} test={test} sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4}/>
            <Count/>
            <Four fourHeading={fourHeading} fourText1={fourText1} fourText2={fourText2} fourText3={fourText3} fourImage={fourImage}/>
            <SuccessStories/>
            <Five fiveHeading={fiveHeading} fiveText={fiveText} fiveImage={fiveImage}/>
            <ContactUs/>
            <Hero2/>
            <Achievement/>
            <Request />
            <Callback />
        </div>
    )
}

export default Logistics
