import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

// MEDIA
// import video from "../assets/video.mp4"


import t1 from '../assets/money.svg'
import t2 from '../assets/fund.svg'
import t3 from '../assets/consultant.svg'
import t4 from '../assets/planning.svg'

const sub1 = "Financial Planning"
const sub2 = "Cash Investment"
const sub3 = "Consultancy"
const sub4 = "Invest Planning"



const Strategies = () => {
    return (
        <div className="strategies">
            <div className="strategies-left">
                <div className="strategies-card">
                    <div className="dash" data-aos="fade-up" data-aos-once="true"></div>
                    <h3 data-aos="fade-right" data-aos-once="true">OUR MAIN GOAL</h3>
                    <h1 data-aos="zoom-in" data-aos-once="true">STRATEGIES THAT WORKS</h1>
                    <Link to="/contact">
                        <button data-aos="fade-up" data-aos-once="true">GET IN TOUCH</button>
                    </Link>
                </div>
            </div>

            <div className="three-right">
               <div className="three-right-center">
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                       <img src={t1} alt="t1"/>
                       <h3>{sub1}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t2} alt="t2"/>
                        <h3>{sub2}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t3} alt="t3"/>
                        <h3>{sub3}</h3>
                   </div>
                   <div className="three-card" data-aos="slide-up" data-aos-once="true">
                        <img src={t4} alt="t4"/>
                        <h3>{sub4}</h3>
                   </div>
               </div>
           </div>

        </div>
    )
}

export default Strategies
