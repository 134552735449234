import React from 'react'

// COMPONENTS
import Achievement from '../components/Achievement'
import Callback from '../components/Callback'
import ContactUs from '../components/ContactUs'
import Count from '../components/Count'
import Hero2 from '../components/Hero2'
import Request from '../components/Request'
import SuccessStories from '../components/SuccessStories'
import Five from '../subsidiaries/components/Five'
import Four from '../subsidiaries/components/Four'
import One from '../subsidiaries/components/One'
import Three from '../subsidiaries/components/Three'
import Two from '../subsidiaries/components/Two'
import ImportExportHero from '../subsidiaries/ImportExportHero'



import new1 from "../assets/new1.webp";
import ship from "../assets/ship.webp";
import plane from "../assets/plane.webp";

import t1 from '../assets/money.svg'
import t2 from '../assets/fund.svg'
import t3 from '../assets/consultant.svg'
import t4 from '../assets/planning.svg'
import SEO from '../components/SEO'



// ONE
const text1="Financial Planning" 
const text2="Strong Partnership" 
const text3="Customer Insights"

// TWO
const texts1 = "We are trusted investment company";
const texts2 = "In the face of dire economic straits occasioned by the worst recession experienced in the chequered history of the country, a situation that gave rise to high inflation rate  leaving many lacking the ability to afford their daily needs including household items. There is the urgent need for a window to address the hapless situations of the people especially those in paid employment."

// THREE
const heading="Why We are the Best";
const test =  `Lighthill Investment Company Ltd is a multiple-ranged products and services company, with its subsidiary companies involved in the following businesses – Currency trading & investment, Bureau De Change energy, logistics services ,Real Estate and Consumer loan & lease services. Our Currency trading & investment, Bureau De Change business is managed by Lighthill Investment and Insighthills BDC which focuses on financing trade & investment, Bureau De Change etc. `
const sub1 = "Financial Planning"
const sub2 = "Cash Investment"
const sub3 = "Consultancy"
const sub4 = "Invest Planning"

// FOUR
const fourHeading = "LIGHTHILL INVESTMENT";
const fourText1 = "Lighthill Investment & Procurement Company Limited is a financial services company that provides a wide range of credit solutions. Our platforms are specifically designed to meet the needs of individual, corporate and institutional customers."
const fourText2 = "Our focus is Consumer Lending and Asset Leasing. This enables us to fulfil our primary objective of delivering competitive investment performance, along with the highest levels of customer services.";
const fourText3 = "For years we have been satisfying our customers in a wide range of products we offered at their convenience; such as Cruise Control Package (CCP), Boom Town Package (BTP) and Bailout Package (BOP)."
const fourImage = "https://res.cloudinary.com/devsource/image/upload/v1623001072/Lighthill/smartmockups_kplgwzkw_lb7gj0.jpg"


// FIVE
const fiveHeading = "LIGHTHILL INVESTMENT";
const fiveText = `We hold ourselves accountable for the disciplined management of risk and for doing the right thing.
We strive to create long-term value for our stakeholders through strong business fundamentals, consistent with our mission guided by our vision and directed by our company’s core values.
Our top priority, every minute of the day, is to serve our customers the very best we can(Customers First). To do this, we rely on the quality and commitment of our people(People Development), and on our ability to cooperate and generate synergies
We are known for delivering on our promises to our customers, and are recognized as a trusted brand and one of the world’s most admired companies.`
const fiveImage = "https://res.cloudinary.com/devsource/image/upload/v1622997012/Lighthill/faq-main_xiopia.png"
const import_Export = () => {
    return (
        <div>
            <SEO title="Import and Export"/>
            <ImportExportHero/>
            <One img1={new1} img2={ship} img3={plane} text1={text1} text2={text2} text3={text3}/>
            <Two texts1={texts1} texts2={texts2}/>
            <Three t1={t1} t2={t2} t3={t3} t4={t4} heading={heading} test={test} sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4}/>
            <Count/>
            <Four fourHeading={fourHeading} fourText1={fourText1} fourText2={fourText2} fourText3={fourText3} fourImage={fourImage}/>
            <SuccessStories/>
            <Five fiveHeading={fiveHeading} fiveText={fiveText} fiveImage={fiveImage}/>
            <ContactUs/>
            <Hero2/>
            <Achievement/>
            <Request />
            <Callback />
        </div>
    )
}

export default import_Export
