import React from 'react'
import { useHistory } from 'react-router';


// PACKAGES

const InvestmentHero = () => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }
    return (
        <div className="investmentHero-hero">
            <div className="investmentHero-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>LIGHTHILL INVESTMENT</h1>
                <p>We deliver great value to our customers at the best prices while ensuring optimal value for stakeholders.</p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>

        </div>
    )
}

export default InvestmentHero
