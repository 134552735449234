
const content = [
    {
        start: "We Help",
        title: "Grow Business",
        description:
            "We relentlessly seek innovative solutions that delivers great value to our customers at higly competitive prices while ensuring optimal value for our stakeholders.",
        button: "Contact Us",
        image: "https://res.cloudinary.com/devsource/image/upload/v1621546949/Lighthill/home5_yqrrfv.jpg",
    },
    {
        title: "LIGHTHILL INVESTMENT",
        description:
            "Our goal is to provide our investors with a reliable source of high income, while minimizing any possible risks and offering a high-quality service.",
        button: "Discover",
        image: "https://res.cloudinary.com/devsource/image/upload/v1621546950/Lighthill/home6_r5to2e.jpg",
    },
    {
        title: "HEIGHTHILLS SOLUTIONS",
        description:
            "We are a dedicated team of truly passionate, property professionals who understand our clients’ needs and wants.",
        button: "Join Us Today",
        image: "https://res.cloudinary.com/devsource/image/upload/v1621553114/Lighthill/1619857377_home-loan_qtvo1h.webp",
    },
    {
        title: "INSIGHTHILL LEASING",
        description:
            "Insighthills Leasing Company Limited (ILCL) is a financial services company that provides a wide range of credit solutions. Our platforms are specifically designed to meet the needs of individuals.",
        button: "Get In Touch",
        image: "https://res.cloudinary.com/devsource/image/upload/v1621553191/Lighthill/65-Stunning-Modern-Dream-House-Exterior-Design-Ideas-61_xzwtoy.jpg",
    },
    {
        title: "LIGHTHILL LOGISTICS",
        description:
            "We facilitate deliveries by offering pick-up and drop-off services for small to medium sized packages within and outside Lagos state for both businesses and individuals.",
        button: "Reach Out Today",
        image: "https://res.cloudinary.com/devsource/image/upload/v1625129226/Lighthill/transportation-logistics-container-cargo-ship-cargo-plane_37416-103-1-1_t3viyv.jpg",
    },
    {
        title: "INSIGHTHILL BDC",
        description:
            "We offers a diverse and esteemed clientele foreign exchange services at competitive rates using qualified manpower while operating within the stipulated regulatory framework.",
        button: "Get Started",
        image: "https://res.cloudinary.com/devsource/image/upload/v1621670478/Lighthill/absolutvision-uCMKx2H1Y38-unsplash_qbflvy.jpg",
    }
];

export default content