import React from 'react'
import { useHistory } from 'react-router'

// PACKAGES

const LeasingHero = () => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }
    return (
        <div className="LeasingHero-hero">
            <div className="LeasingHero-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>INSIGHTHILLS LEASING</h1>
                <p>
                For years we have been satisfying our customers in a wide range of products we offered at their convenience; such as Cruise Control Package (CCP), Boom Town Package (BTP) and Bailout Package (BOP).
                </p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>

        </div>
    )
}

export default LeasingHero
