import React, {useEffect} from 'react'

// PACKAGES
import { BrowserRouter as Router } from 'react-router-dom'
import Aos from 'aos';
import "aos/dist/aos.css";

// COMPONENTS
import Header from './components/Header'
import Routes from './components/Routes';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';





function App() {

    // THE SECTION OF THE AOS
    useEffect(() => {
      Aos.init({ duration: 1200 })
    }, []);

  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Header/>
          <Routes />
          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
