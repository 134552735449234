import React from 'react'

// COMPONENTS
import GalleryHero from '../components/GalleryHero'
import SEO from '../components/SEO'
import Viewer from '../components/Viewer'

const Gallery = () => {
    return (
        <div>
            <SEO title="Gallery"/>
            <GalleryHero />
            <Viewer />
        </div>
    )
}

export default Gallery
