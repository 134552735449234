import React from "react"
import support from '../assets/support.svg'
import worldwide from '../assets/worldwide.svg'
import call_center from '../assets/call_center.svg'

const services = [

    {
        id: 2,
        icon: <img src={support} alt="call_center" className="blue" />,
        title: "A Team Of Professionals",
        text: `We pride ourselves with an excellent and industry tested team.`,
    },
    {
        id: 1,
        icon: <img src={call_center} alt="support" className="green" />,
        title: "On Time Service",
        text: `Known for the best time management in getting the best services and products to clients`,
    },
    {
        id: 3,
        icon: <img src={worldwide} alt="worldwide" className="yellow" />,
        title: "A Global Reference",
        text: `We are a global reference point in wealth creation and business establishment.`,
    },

]

export default services