import React from 'react'

const Two = ({texts1, texts2}) => {
    return (
        <div className="two">
            <div className="two-left">
                <div className="underlines" data-aos="fade-righ" data-aos-once="true"></div>
               <h1 data-aos="zoom-i" data-aos-once="true">{texts1}</h1>
            </div>

            <div className="two-right">
               <p data-aos="zoom-ou" data-aos-once="true">{texts2}</p>
            </div>
        </div>
    )
}

export default Two
