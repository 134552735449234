import React from "react"
import leadership from '../assets/leadership.svg'
import competition from '../assets/competition.svg'
import sprout from '../assets/sprout.svg'

const services = [

  {
    id: 2,
    icon: <img src={leadership} alt="leadership" />,
    title: "Our Leadership",
    text: `Our leaders deliver for our clients and shareholders through a strategy of responsible growth and a culture of accountability built on values, teamwork and managing risk well`,
  },
  {
    id: 1,
    icon: <img src={competition} alt="competition" />,
    title: "Business Matching",
    text: `We are the leading players in the currency BDC, agro-allied, import and export, property, investment, logistics and agro-allied industry`,
  },
  {
    id: 3,
    icon: <img src={sprout} alt="sprout" />,
    title: "Growing Your Business",
    text: `We take your business to a greater height and higher ground with the wealth of experience and expertise of our employee in providing the best business service.`,
  },

]

export default services