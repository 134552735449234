import React from 'react'
import data from '../constants/Care'


const Care = () => {
    return (
        <div className="care">
            <div className="care-center">
                {
                    data.map((item) => (
                        <div key={item.id} className="care-one">
                            <div className="care-left">
                                <h1>{item.id}</h1>
                            </div>
                            <div className="care-right">
                                <h3>{item.title} </h3>
                                <p>{item.text}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Care
