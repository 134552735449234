import React from 'react'

// PACKAGES
import {useHistory} from 'react-router-dom'


const DetailHero = (props) => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }
    return (
        <div className="detail-hero">
            <div className="detail-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>
        </div>
    )
}

export default DetailHero
