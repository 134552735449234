import React from 'react'

// PACKEGES
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'

// COMPONENTS
import subsidiaries from '../constants/subsidiaries'

const Subsidiary = () => {
    return (
        <>
            <section className="subsidiary">
                <h3 data-aos="fade-down" data-aos-once="true">Our Services</h3>
                {/* <div className="underlines"></div> */}
                <div className="sub-center">
                    {subsidiaries.map((service) => {
                        const { id, url, icon, title, text } = service;
                        return (
                            <article key={id} className="sub" data-aos="zoom-in" data-aos-once="true">
                                <div className="sub-image">
                                    {icon}
                                </div>

                                <div className="sub-text">
                                    <h2>{title}</h2>
                                    <div className="under"></div>
                                    <p>{text}</p>
                                </div>

                                <Link to={url}>
                                    <div className="click">
                                        <FaArrowRight className="click-icon" />
                                    </div>
                                </Link>
                            </article>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default Subsidiary
