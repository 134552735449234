import React from 'react'
import { useHistory } from 'react-router'

// PACKAGES

const SolutionHero = () => {
    const history = useHistory()

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="SolutionHero-hero">
            <div className="SolutionHero-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>HEIGHTHILLS SOLUTION</h1>
                <p>
                    For over eight years HEIGHTHILLS SOLUTIONS has been building a wealth of experience and knowledge in the high-value, luxury property market. 
                </p>
                <p onClick={handleBack} className="back">Go Back</p> 
            </div>

        </div>
    )
}

export default SolutionHero
