import React from 'react'

// IMAGES
import user from '../assets/value.svg'
import rating from '../assets/rating.svg'
import financial from '../assets/financial.svg'
import document from '../assets/document.svg'

const SuccessStories = () => {
    return (
        <div className="success_stories">
            <h2 data-aos="fade-down" data-aos-once="true">Our Success Stories</h2>
            <p data-aos="fade-up" data-aos-once="true">WHAT WE HAVE DONE</p>

            <div className="success-center">
                <div className="success-one">
                    <div className="success-left">
                        <img src={rating} alt="stories" />
                    </div>
                    <div className="success-right">
                        <h3>10,000+ </h3>
                        <p>All time Clients</p>
                    </div>
                </div>

                <div className="success-one">
                    <div className="success-left">
                        <img src={user} alt="stories" />
                    </div>
                    <div className="success-right">
                        <h3>979 </h3>
                        <p>Clients this year</p>
                    </div>
                </div>
                
                <div className="success-one">
                    <div className="success-left">
                        <img src={financial} alt="stories" />
                    </div>
                    <div className="success-right">
                        <h3>50+</h3>
                        <p>Avg Profits increased</p>
                    </div>
                </div>
                <div className="success-two">
                    <div className="success-left">
                        <img src={document} alt="stories" />
                    </div>
                    <div className="success-right">
                        <h3>300+</h3>
                        <p>New Projects</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SuccessStories
