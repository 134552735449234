const Care = [
    {
        id:1,
        title:"Money Care",
        text: "Finding your next Advisor"
    },
    {
        id:2,
        title:"Friendly Assistance",
        text: "Export Financial Advice"
    },
    {
        id:3,
        title:"Our Experience",
        text: "12 Years Of Experience"
    },
    {
        id:4,
        title:"Client Investment",
        text: "Doing The Right Thing"
    },
]

export default Care