import React from 'react'

//  COMPONENTS
import Hero from '../components/Hero'
import Subsidiary from '../components/Subsidiary'
import Request from '../components/Request'
import Callback from '../components/Callback'
import Achievement from '../components/Achievement'
import SuccessStories from '../components/SuccessStories'
import Trade from '../components/Trade'
import Opportunity from '../components/Opportunity'
import SEO from '../components/SEO'


const Subsidiaries = () => {
    return (
        <div>
            <SEO title="Subsidiaries"/> 
            <Hero/>
            <Subsidiary/>
            <Opportunity/> 
            <Trade/> 
            <Achievement/>
            <SuccessStories/>
            <Request/>
            <Callback/>
        </div>
    )
}

export default Subsidiaries
