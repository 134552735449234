import React from 'react'

// COMPONENTS
import Achievement from '../components/Achievement'
import Callback from '../components/Callback'
import ContactUs from '../components/ContactUs'
import Count from '../components/Count'
import Hero2 from '../components/Hero2'
import Request from '../components/Request'
import SuccessStories from '../components/SuccessStories'
import Five from '../subsidiaries/components/Five'
import Four from '../subsidiaries/components/Four'
import One from '../subsidiaries/components/One'
import Three from '../subsidiaries/components/Three'
import Two from '../subsidiaries/components/Two'
import BdcHero from '../subsidiaries/BdcHero'




import new1 from "../assets/new1.webp";
import ship from "../assets/ship.webp";
import plane from "../assets/plane.webp";

import t1 from '../assets/money.svg'
import t2 from '../assets/fund.svg'
import t3 from '../assets/consultant.svg'
import t4 from '../assets/planning.svg'
import SEO from '../components/SEO'



// ONE
const text1="Creative Ideas" 
const text2="Easy to manage fund" 
const text3="Risk Management"

// TWO
const texts1 = "We take extra care of your business"; 
const texts2 = `Insighthills Bureau De Change Ltd is a foreign exchange company licensed by the
                Central Bank of Nigeria (CBN) on the 26th July, 2016 and registered with the
                Association of Bureau De Change Operators of Nigeria (ABCON) to carry on Bureau De
                Change business in Nigeria hereby bridging the gap between the official and parallel
                market.
                `

// THREE
const heading="Why We are the Best";
const test =  `
                Our company with her available resources and partnerships with brokers clientele, no
                doubt provides prospective customers with fast, efficient , reliable and competitive
                Value Added Services.
                We offers a diverse and esteemed clientele foreign exchange services at competitive
                rates using qualified manpower while operating within the stipulated regulatory
                framework.
                Through us, businesses and individuals can place orders and broker foreign currency
                transactions timely and more conveniently. WE trade in major global currencies,
                including the US dollar, Uk pound Sterling and the Euro etc.
                `
const sub1 = "business Growth"
const sub2 = "Strategy Process"
const sub3 = "Consultancy"
const sub4 = "Finance Management"

// FOUR
const fourHeading = "OUR SERVICES";
const fourText1 = `We are strategically partnered with legally and reliable agents abroad for swift and
                    immediate fund remittance. We manage personal -to- personal remittance, personal -to-
                    business and business -to- business form of remittance.
                    `
const fourText2 = `We offer specialist advice to our clients on forex transactions. Our duties to our
                customers involves in consistent research of the marketplace, forecasting based on
                global derivatives and recommending most appropriate forex stock and sales.
                `
const fourText3 = `Our Business offers varying currency exchange with swift seamless payments to all our
                    clients, we are securely and safely dependable for your cash purchase and sales of forex.
                    `
const fourImage = "https://res.cloudinary.com/devsource/image/upload/v1623342086/Lighthill/news-16_kzgwcy.jpg"


// FIVE
const fiveHeading = "INSIGHTHILLS BDC";
const fiveText = `We hold ourselves accountable for the disciplined management of risk and for doing the right thing.
We strive to create long-term value for our stakeholders through strong business fundamentals, consistent with our mission guided by our vision and directed by our company’s core values.
Our top priority, every minute of the day, is to serve our customers the very best we can(Customers First). To do this, we rely on the quality and commitment of our people(People Development), and on our ability to cooperate and generate synergies
We are known for delivering on our promises to our customers, and are recognized as a trusted brand and one of the world’s most admired companies.`
const fiveImage = "https://res.cloudinary.com/devsource/image/upload/v1623342242/Lighthill/1_nzfu15.jpg"


const Agro_Allied = () => {
    return (
        <div>
            <SEO title="BDC"/>
            <BdcHero/>
            <One img1={new1} img2={ship} img3={plane} text1={text1} text2={text2} text3={text3}/>
            <Two texts1={texts1} texts2={texts2}/>
            <Three t1={t1} t2={t2} t3={t3} t4={t4} heading={heading} test={test} sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4}/>
            <Count/>
            <Four fourHeading={fourHeading} fourText1={fourText1} fourText2={fourText2} fourText3={fourText3} fourImage={fourImage}/>
            <SuccessStories/>
            <Five fiveHeading={fiveHeading} fiveText={fiveText} fiveImage={fiveImage}/>
            <ContactUs/>
            <Hero2/>
            <Achievement/>
            <Request />
            <Callback />
        </div>
    )
}

export default Agro_Allied
