import React from 'react'

// IMAGES
import log1 from '../assets/log1.svg'
import log2 from '../assets/log2.svg'
import log3 from '../assets/log3.svg'
import log4 from '../assets/log4.svg'
import log5 from '../assets/log5.svg'

// PACKAGES
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";


const Hero2 = () => {
    return (
        <div className="heros2">
            <h2 data-aos="fade-down" data-aos-once="true">Our Clients</h2>
            <Swiper watchSlidesProgress={true} watchSlidesVisibility={true} slidesPerView={5} className="mySwiper">
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log1} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log2} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log3} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log4} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log5} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log2} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log1} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log3} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log1} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log2} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log3} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log4} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log5} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log2} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log1} alt="log-pic" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swipe-image">
                        <img src={log3} alt="log-pic" />
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}

export default Hero2
