import React from 'react'

// PACKAGES
import { Route, Switch} from 'react-router-dom'

// COMPONENTS
import About from '../pages/About'
import Blog from '../pages/Blog'
import Contact from '../pages/Contact'
import Gallery from '../pages/Gallery'
import Home from '../pages/Home'
import Services from '../pages/Services'
import Subsidiaries from '../pages/Subsidiaries'


import Bdc from '../pages/Bdc'
import Solution from '../pages/solution'
import Leasing from '../pages/Leasing'
import ImportExport from '../pages/ImportExport'
import Investment from '../pages/Investment'
import Logistics from '../pages/Logistics'
import Detail from './Detail'
import Default from '../pages/Default'


const Routes = () => {
    return ( 
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/services" exact component={Services} />
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/subsidiaries" exact component={Subsidiaries} />
            <Route path="/gallery" exact component={Gallery} />
            <Route path="/blog" exact component={Blog} />

            <Route path="/details/:id" exact component={Detail } />

            <Route path="/bdc" exact component={Bdc} />
            <Route path="/solution" exact component={Solution} />
            <Route path="/import_export" exact component={ImportExport} />
            <Route path="/investment" exact component={Investment} />
            <Route path="/logistics" exact component={Logistics} />
            <Route path="/leasing" exact component={Leasing} />

            <Route exact component={Default} />
        </Switch>
    )
}

export default Routes
