import React from "react"

// IMAGES
import adeola from '../assets/adeola.jpg'
import bolaji from '../assets/bolaji.jpg'
import Damilola from '../assets/Damilola.jpg'
import martins from '../assets/martins.jpg'
import mudiaga from '../assets/mudiaga.jpg'
import olusegun from '../assets/olusegun.jpg'
import oluwatoyin from '../assets/oluwatoyin.jpg'
import omoteniola from '../assets/omoteniola.jpg'
import seun from '../assets/seun.jpg'
import adeyemi from '../assets/adeyemi.jfif'


const Team = [

    {
        _id: 'OLUSEGUN AKINNIRAN',
        icon: <img src={olusegun} alt="olusegun" />,
        title: "OLUSEGUN AKINNIRAN",
        text: `Group Managing Director`,
        info: "A far sighted individual characterized as a visionary,strategist and tactician with excellent business development skills, His superior experience with New Money Link, CIT Bureau De Change Ltd and Capital Field Investment and Trust Ltd as a manager proved his ability to constantly manage both financial and physical resources in other to engender optimum business. During his time in Capital field leading rapidly growing business in the region, rolling out a new strategy to further accelerate the company growth, strengthen its competitive position and reposition the company around its customers. He holds BSC in Accounting, MBA in Finance from Obafemi Awolowo University. He is also a full member of Institute of Strategic Management of Nigeria and a member of the Financial Services Group of the Lagos Chamber of Commerce and Industry. Olusegun set a vision and strategy for growth and built one a fast growing organization. he also developed new revenue opportunities including real estate,logistics, agro allied to mention a few. He developed the Company’s use of financial futures for its hedging strategies, including managing and trading the futures’ book."
    },
    {
        _id: 'OLUWASEUN POPOOLA',
        icon: <img src={seun} alt="seun" />,
        title: "OLUWASEUN POPOOLA",
        text: `General Manager`,
        info:`He is the Overall General Manager of Lighthill, and a member of the company’s executive
        management team. His responsibilities include oversight of the company’s daily activities, which
        includes, Investment, Risk Management, Execution of daily transactions , management
        businesses are among all others,
        Prior to his position as the company General Manager, Oluwaseun was the Business Developing
        Manager in Lighthill, and was responsible for Corporate Strategy and the company’s market
        engagement activities. He also established and had responsibility for Control Manager,
        overseeing the company’s governance and strategy for risk management.
        Before joining Lighthill, He has worked in Banks and other Financial Sectors as an Accountant.
        He graduated top of His class at University of Ibadan with a first class degree.
        `
    },
    {
        _id: 'AYENI MARTINS',
        icon: <img src={martins} alt="martins" />,
        title: "AYENI MARTINS",
        text: `Chief Financial Officer`,
        info:`AYENI MAYOWA MARTINS ( CFO) is our Chief financial officer , with responsibility for the
        overall financial management of the company, including accounting, balance sheet management,
        financial planning and analysis, corporate treasury, investor relations, corporate investments
        and tax. He is also a member of the company’s executive management team with responsibility
        for overseeing the company Investment portfolio audit and revenue, as well as corporate
        planning, forecasting and board reporting.
        Prior to his current position, Ayeni has served in many key roles in financial world and
        corporate investment. He worked in Bestbrand Nig, ltd as an accountant and Nroeba &amp; Co as an
        Audit &amp; Assurance Departmental head.
        `
    },
    {
        _id: 'OLUWATOYIN AKINNIRAN',
        icon: <img src={oluwatoyin} alt="oluwatoyin" />,
        title: "OLUWATOYIN AKINNIRAN",
        text: `Executive Director`,
        info:``
        
    },
    {
        _id: 'ADEOLA IDOWU',
        icon: <img src={adeola} alt="adeola" />,
        title: "ADEOLA IDOWU",
        text: `HEAD, Account`,
        info:`Adeola is a confident and highly motivated individual with a drive for excellence. He
        majored in Food Supply chain and holds a bachelor’s degree Food Technology from FUTA. His
        passions for promoting food security and advancing trade and economic development are also
        reflected in his work and ethics.
        Adeola is responsible for strategy and execution for Heighthills’s agriculture produce
        businesses, from planning to cultivation, sales management and procceds. He has earned a reputation for delivering better and comprehensive results.
        `
    },
    {
        _id: 'ADEYEMI KASSIM',
        icon: <img src={adeyemi} alt="adeyemi" />,
        title: "ADEYEMI KASSIM",
        text: `MD Heighthills Solutions`,
        info:``
    },
    {
        _id: 'DAMILOLA IDOWU',
        icon: <img src={Damilola} alt="Damilola" />,
        title: "DAMILOLA IDOWU",
        text: `IT/ Relationship Manager`,
        info:`Damilola started her career as a customer service manage, she has worked in different sector like maritime, multimedia and financial organization. she developed her passion for IT and her interest grow over time in research, web programming and build-up her experience in any IT related field. She is working in various departments of the organization, ranging from sales , leasing and IT. Her core function is to monitor progress with the help of key staff of the organization. Running
        regular checks on network and data security. Identifying and acting on opportunities to improve and update software and systems. Developing and implementing IT policy and best practice guides for the organization and also provide users with technical support for computer problems.
        She also oversees Lighthill’s communications, brand and corporate responsibility functions.
        `
    },
    {
        _id: 'BOLAJI OLADUNJOYE',
        icon: <img src={bolaji} alt="bolaji" />,
        title: "BOLAJI OLADUNJOYE",
        text: `MD, Lighthills Logistics`,
        info: `Bolaji’s vast experience in third party logistics makes him the ideal leader for LLC and a strong
        partner for our teeming customers.
        In ensuring the highest quality standards are met, for over two years, Bolaji has used his skills in
        logistics to guide fleet drivers towards excellence in daily activities.
        His work ethic and natural leadership ability saw him elevated to a supervisory role with the
        company in 2016.
        Bolaji likes to lead by example. He sees logistics-related problems as a challenge and strives to
        overcome them for utmost customer satisfaction. Through hard work and dedication, he has
        built an energetic and fast growing company.
        `
    },
    {
        _id: 'OMOTENIOLA EBODA',
        icon: <img src={omoteniola} alt="omoteniola" />,
        title: "OMOTENIOLA EBODA",
        text: `Head, Currency Trade`,
        info:`Teniola holds a Bachelor of Science degree in Business Administration from University of Ado
        Ekiti. She started her career as a relationship manager with Lighthill and grow over the year in
        good experience in the industry and comprehensive knowledge of the financial marketing
        terrain, she has established herself as expert in managing clients and ensuring flow of successful
        transactions. She is able to efficiently facilitate the needs of her clients bringing integrity,
        honesty, and market intelligent
        `
    },
    {
        _id: 'MUDIAGA IMARAH',
        icon: <img src={mudiaga} alt="mudiaga" />,
        title: "MUDIAGA IMARAH",
        text: `MD, Insighthills Leasing`,
        info:`Mudiaga is articulate and pay attention details. He is a graduate of Accounting and hold ICAN
        certificate. He majored in accounting and built his experience in a financial companies. He has
        over 5years experience as an accountant. He is presently the Head of Insighthills leasing and has
        maintained excellent quality and safety records while building a formidable team capable of
        handling supply chain changes. He brings innovative ideas and solutions to drive cost savings
        and customer satisfaction.
        `
    },
    

]

export default Team