import React from 'react'

// COMPONENTS
import AboutCard from '../components/AboutCard'
import AboutHero from '../components/AboutHero'
import Achievement from '../components/Achievement'
import Hero2 from '../components/Hero2'
import Know from '../components/Know'
import Team from '../components/Team'
import Talk from '../components/Talk'
import Request from '../components/Request'
import Callback from '../components/Callback'
import TeamText from '../components/TeamText'
import Plan from '../components/Plan'
import SuccessStories from '../components/SuccessStories'
import SEO from '../components/SEO'

 

const About = () => {
    return (
        <div>
            <SEO title="About Us" />
            <AboutHero/>
            <AboutCard />
            <Know />
            <TeamText />
            <Team />
            <Talk />
            <Plan />
            <SuccessStories /> 
            <Achievement />
            <Hero2 />
            <Request />
            <Callback />
        </div>
    )
}

export default About
