import React from 'react'

// PACKAGES
import {Link} from 'react-router-dom'

// COMPONENTS
import SocialLinks from "../constants/socialLinks"


// IMAGES
import logo from '../assets/logos.jpeg'
import bolaji from '../assets/bolaji.jpg' 
import adeola from '../assets/adeola.jpg' 
import olusegun from '../assets/olusegun.jpg' 
import Damilola from '../assets/Damilola.jpg' 
import martins from '../assets/martins.jpg' 
import mudiaga from '../assets/mudiaga.jpg' 
import seun from '../assets/seun.jpg' 
import omoteniola from '../assets/omoteniola.jpg' 

const Footer = () => {
    return (
        <footer> 
            <div className="footers">
                <div className="footer">
                    <img src={logo} alt="footer-pic" />
                    <p>We deliver great value to our customers at the best prices while ensuring optimal value for stakeholders</p>
                    <p>Follow us @ </p>
                    <SocialLinks styleClass="footer-links"/>
                </div>
                <div className="footer">
                    <h4>Contact Us</h4>
                    <p>info @lighthillng.com</p>
                    <p>+234 (0)1291 9028</p>
                    <p></p>
                </div>
                <div className="footer">
                    <h4>Address</h4>
                    <p>1, Alhaja Abass street. Ogudu, Lagos</p>
                    <p>P.O.Box 8862, Somolu, Lagos.</p>
                </div>
                <div className="footer">
                   <Link to="/gallery">
                        <div className="footer-image">
                            <img src={bolaji} alt="footer-pic" />
                            <img src={adeola} alt="footer-pic" />
                            <img src={olusegun} alt="footer-pic" />
                            <img src={Damilola} alt="footer-pic" />
                            <img src={omoteniola} alt="footer-pic" />
                            <img src={martins} alt="footer-pic" />
                            <img src={mudiaga} alt="footer-pic" />
                            <img src={seun} alt="footer-pic" />
                        </div>
                   </Link>
                </div>
            </div>
            <div className="small">copyright &copy; {new Date().getFullYear()}, All Rights Reserved</div>
        </footer>
    )
}

export default Footer
