import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Context } from '../Context'
import DetailHero from './DetailHero'
import Achievement from './Achievement'
import SEO from './SEO'
// COMPONENTS

const Detail = () => {
    const [product] = useContext(Context)
    const params = useParams()
    const [detailProduct, setDetailProduct] = useState([])



    useEffect(() => {
        if (params) {
            product.forEach(item => {
                // eslint-disable-next-line
                if (item._id == params.id) setDetailProduct(item)
            })

        }
    }, [params, product])

    const { icon, info, title, text } = detailProduct;

    return (
        <>
        <SEO title={title}/>
            <DetailHero title={title} text={text}/>
            <div className="details">
                <div className="details-left">
                    <div className="details-left-div">
                        {icon}
                    </div>
                </div>

                <div className="details-right">
                    <div className="details-right-div">
                        <h1>{title}</h1>
                        <h3>{text}</h3>

                        <div className="under"></div>
                        <h2>BIOGRAPHY</h2>
                        <p>{info}</p>

                    </div>
                </div>
            </div>
            <Achievement />
        </>
    )
}


export default Detail
