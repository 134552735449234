import React from 'react'
// import { FaArrowRight } from 'react-icons/fa'

const Partner = () => {
    return (
        <div className="partner">
            <div className="partner-left">
                <div className='partner-left-center' data-aos="fade-right" data-aos-once="true">
                    {/* <h1>We provide a <span>reliable</span> and secure way to make foreign <span>payments...</span></h1> */}
                </div>
            </div>

            <div className="partner-right">
                <div className="partner-right-center">
                    <h3 data-aos="fade-up" data-aos-once="true">WE ARE EXCEPTIONAL</h3>
                    <p data-aos="zoom-in" data-aos-once="true">
                        {/* <FaArrowRight className="partner-icon"/> */}
                        Our goal is to provide our investors with a reliable source of high income,
                         while minimizing any possible risks and offering a high-quality service.
                    </p>

                    <p data-aos="zoom-in" data-aos-once="true">
                        {/* <FaArrowRight className="partner-icon"/> */}
                        We offer specialist advice to our clients on forex transactions. Our duties to our
                        customers involves in consistent research of the marketplace, forecasting based on
                        global derivatives and recommending most appropriate forex stock and sales.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Partner
