import axios from 'axios'
import React, {useState} from 'react'
import file from '../assets/undraw.svg'


// THE INITIAL STATE
const initialState = {
    fullname: "",
    email: "",
    mobile: "",
    message: ""
}


const Callback = () => {
    const [user, setUser] = useState(initialState)
    const [sent, setSent] = useState(false)
    const [button, setButton] = useState('SEND');

    const {fullname, email, mobile, message} = user


    // ONCHANGE INPUT
    const onChangeInput = e => {
        const {name, value} = e.target;
        setUser({...user, [name]: value})
    }

    // SUBMITTING THE FORM
    const callbackSubmit = async e => {
        e.preventDefault()
        

        try{
            await axios.post('https://lighthilling.herokuapp.com/user/contact', {
                fullname, email, mobile, message
            })

            // setUser({...user}, console.log(res.data))
            
            e.target.reset()
            setSent(true)
            setButton('SUBMITTED');
            setTimeout(() => {
                setButton('SEND')
            }, 5000);
        }
        catch(err){
            console.log(err)
        }

        setTimeout(() => {
           setSent(false)
        }, 3500)
    }



    return (
        <div className="callback">
            <div className="callback-left" data-aos="fade-right" data-aos-once="true">
                <img src={file} alt="callback-pic" />
            </div>

            <div className="callback-right" data-aos="zoom-in" data-aos-once="true">
                <div className="callback-contact">
                   <form onSubmit={callbackSubmit}>
                    <label htmlFor="name">FullName</label>
                        <input type="text" name="fullname" onChange={onChangeInput} placeholder="Enter your full name" required/>

                        <label htmlFor="name">Email</label>
                        <input type="text" name="email" onChange={onChangeInput} placeholder="Enter your email" required/>

                        <label htmlFor="name">Mobile</label>
                        <input type="text" name="mobile" onChange={onChangeInput} placeholder="Enter your mobile no" required/>

                        <label htmlFor="name">Message</label>
                        <textarea onChange={onChangeInput} name="message" disabled={message.length === 500 && true} required/>
                        <small style={{textAlign:"center",color:"red"}}>{message.length}/500</small>

                        <p className={sent ? 'msg msgAppear' : 'msg'}>Message has been sent</p>

                        <button className="btn">{button}</button>
                   </form>
                </div>
            </div>
        </div>
    )
}

export default Callback
