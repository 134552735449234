import React from 'react'


const One = ({img1, text1,img2, text2,img3, text3}) => {
    return (
        <div className="one">
            <div className="one-center">
                <div className="one-card" data-aos="fade-up" data-aos-once="true">
                    <div className="one-card-center">
                        <img src={img1} alt="new1"/>
                        <h2>{text1}</h2>
                    </div>
                </div>
                <div className="one-card" data-aos="fade-up" data-aos-once="true">
                    <div className="one-card-center">
                        <img src={img2} alt="ship"/>
                        <h2>{text2}</h2>
                    </div>
                </div>
                <div className="one-card" data-aos="fade-up" data-aos-once="true">
                    <div className="one-card-center">
                        <img src={img3} alt="plane"/>
                        <h2>{text3}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default One
