import React from 'react'

// PACKAGES
import { FaComment } from 'react-icons/fa'

// IMAGES
const plan = "https://res.cloudinary.com/devsource/image/upload/v1623855474/Lighthill/project-11_q9r672.jpg"

const Plan = () => {
    return (
        <div className="plan">
            <div className="plan-left">
                <div className="plan-top" data-aos="fade-down" data-aos-once="true">
                    <p><FaComment /> <br />Transcending innovation and an innovation
                    culture within our subsidiaries through successive directors, managers
                    and employees with versification through conglomerate or concentric diversification to provide a variety of products and services.
                    </p>
                </div>

                <div className="plan-bottom" data-aos="fade-up" data-aos-once="true">
                    <p><FaComment /> <br />Application of total quality management principles across our
                    product and service chains to ensure the present and future are properly
                    managed, and a human resource approach, that considers employees are key resources that control all other resources.</p>
                </div>
            </div>

            <div className="plan-right">
                <div className="plan-right-center" data-aos="zoom-in" data-aos-once="true">
                    <img src={plan} alt="plan"/>
                </div>
            </div>
        </div>
    )
}

export default Plan
