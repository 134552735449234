import React from 'react'

// IMAGES
import badge from '../assets/badge.svg'

const Achievement = () => {
    return (
        <div className="achievement">
            <div className="achievement-center">
                <h2 data-aos="fade-down" data-aos-once="true">Achievement <img src={badge} alt="badge"/></h2>
                <p data-aos="fade-up" data-aos-once="true">Lighthill Investment Company is a leading investment and financial company that provides a wide range of investment solutions, forex trading services, and real estate management. Our platforms are specifically designed to meet the needs of individual, corporate and institutional clients.</p>
            </div>
        </div>
    )
}

export default Achievement
