import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'


const Trade = () => {
    return (
        <div className="trade">
            <div className="trade-left">
                <div className="trade-left-center">
                    <h2 data-aos="fade-down" data-aos-once="true">We Trade Currency<small></small></h2>
                    
                    <ul data-aos="zoom-in" data-aos-once="true">
                        <li>Foreign currency portfolio management</li>
                        <li>Foreign currency bills settlement (Fees, upkeep, mortgage e.t.c)</li>
                        <li>Foreign currency sales and purchase in cash</li>
                    </ul>
                    <Link to="/bdc">
                        <button data-aos="fade-right" data-aos-once="true">Explore</button>
                    </Link>
                </div>
            </div>


            <div className="trade-right">
                <div className="trade-right-center" data-aos="flip-right" data-aos-once="true">
                    
                </div>
            </div>
        </div>
    )
}

export default Trade
