import React from 'react'

const Request = () => {
    return (
        <div className="request">
            <h2 data-aos="zoom-out" data-aos-once="true">Request a call back?</h2>
            <p data-aos="slide-up" data-aos-once="true">Please fill out the form and press the submit button.
            We will get back to you with 1-2 business days.
            </p>
        </div>
    )
}

export default Request