import React from "react"
import agro from '../assets/agro.svg'
import motor from '../assets/motor.svg'
import flower from '../assets/flower.svg'
// import arrow from '../assets/arrow.svg'
import house from '../assets/house.svg'
import hand from '../assets/hand.svg'

const services = [

    {
        id: 2,
        icon: <img src={flower} alt="flower" />,
        title: "LightHill Investment",
        text: `Managing a process that is highly collaborative and based on the scientific principles of open inquiry, testing...`,
    },
    {
        id: 3,
        icon: <img src={agro} alt="agro" />,
        title: "Heighthills Solutions",
        text: `To be one of the leading players in the real estate industries in Nigeria.`,
    },
    {
        id: 4,
        icon: <img src={house} alt="house" />,
        title: "Insighthills Leasing",
        text: `Brokerage Services For Residential and commercial property`,
    },
    {
        id: 1,
        icon: <img src={motor} alt="motor" />,
        title: "Lighthill Logistics",
        text: `We guarantee unlimited delivery buoyed by border-less logistics.`,
    },
    {
        id: 5,
        icon: <img src={hand} alt="hand" />,
        title: "Insighthills BDC",
        text: `Through us, businesses and individuals can place orders and broker foreign currency transactions timely and more conveniently`,
    },
    // {
    //     id: 6,
    //     icon: <img src={arrow} alt="arrow" />,
    //     title: "Import and Export",
    //     text: `Exportation of food stuff and cash crops.`,
    // },

]

export default services