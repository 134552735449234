import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

const Fast = () => {
    return (
        <div className="fast">
            <div className="fast-center">
                <div className="fast-center-left">
                    <div className="fast-div" data-aos="flip-right" data-aos-once="true">

                    </div>
                </div>

                <div className="fast-center-right">
                    <div className="fast-center-div">
                        <h2 data-aos="fade-right" data-aos-once="true">Our Services...</h2>
                        <div className="client-list" data-aos="zoom-in" data-aos-once="true">
                            <ul>
                                <li><FaCheckCircle className="check" />Foreign Remittance and Local Remittance</li>
                                <li><FaCheckCircle className="check" />Currency Exchange</li>
                                <li><FaCheckCircle className="check" />Rates &amp; Direct Financial Advisory</li>
                                <li><FaCheckCircle className="check" />Foreign Currency Exchange</li>
                                <li><FaCheckCircle className="check" />Foreign Payments</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fast
