import React from "react"
import investment from '../assets/investment.jpg'
import leasing from '../assets/leasing.jpg'
import solution from '../assets/solution.jpg'
import logistics from '../assets/logistics.jpg'
import bdc from '../assets/bdc.jpg'
import impor from '../assets/import.jpg'


const sub = [
  {
    id: 1,
    url: "/investment/",
    icon: <img src={investment} alt="sub-pic" />,
    title: "Lighthill Investment",
    text: `Lighthill is a leading investment and financial company that provides a wide range of investment solutions, forex trade services`,
  },
  {
    id: 2,
    url: "/leasing/",
    icon: <img src={leasing} alt="sub-pic" />,
    title: "Insighthills Leasing",
    text: `Insighthills Leasing Company Limited (ILCL) is a financial services company that provides a wide range of credit solutions. Our platforms are specifically designed to meet the needs of individual, corporate and institutional customers.`,
  },
  {
    id: 3,
    url: "/solution",
    icon: <img src={solution} alt="sub-pic" />,
    title: "Heighthills Solution",
    text: `We are a dedicated team of truly passionate, property professionals who understand our clients’ needs and wants. When making property decisions that impact your family now and in the future, you need a HEIGHTHILLS partner`,
  },
  {
    id: 4,
    url: "/logistics",
    icon: <img src={logistics} alt="sub-pic" />,
    title: "Lighthill Logistics",
    text: `The company boasts of a proven antecedent in offering service flexibility, reliability, accuracy, security and speed. Our team of seasoned professionals work directly with designated agents to provide safe local transport.`,
  },
  {
    id: 5,
    url: "/bdc",
    icon: <img src={bdc} alt="sub-pic" />,
    title: "Insighthills BDC",
    text: `We offers a diverse and esteemed clientele foreign exchange services at competitive
    rates using qualified manpower while operating within the stipulated regulatory
    framework.
    `,
  },
  {
    id: 6,
    // url: "/import_export",
    icon: <img src={impor} alt="sub-pic" />,
    title: "Import and Export",
    text: `Exportation of food stuff, cash crops and charcoal, we continually adapt our services to meet their ever-changing circumstances. It’s not only about delivering the best service`,
  },


]

export default sub