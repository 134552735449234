import React from 'react'
import Accordion from './Accordion';

// IMAGES
const know = "https://res.cloudinary.com/devsource/image/upload/v1623342242/Lighthill/1_nzfu15.jpg";

const Know = () => {
    return (
        <div className="know">
            <h1 data-aos="fade-up" data-aos-once="true">What you need to know about Lighthill</h1>
            <div className="know-center">
                <div className="know-left">
                    <div className="know-left-center" data-aos="fade-right" data-aos-once="true">
                        <img src={know} alt="know"/>
                    </div>
                </div>

                <div className="know-right">
                    <section className='info' data-aos="zoom-in" data-aos-once="true">
                      <Accordion/>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Know
