import React from 'react'

const Five = ({fiveHeading, fiveText, fiveImage}) => {
    return (
        <div className="five">
            <div className="five-left">
                <div className="five-left-center">
                    <h2 data-aos="fade-down" data-aos-once="true">{fiveHeading}</h2>
                    <div className="underline" data-aos="fade-right" data-aos-once="true"></div>
                    <p data-aos="fade-up" data-aos-once="true">{fiveText}</p>
                </div>
            </div>

            <div className="five-right">
                <div className="five-image" data-aos="zoom-in" data-aos-once="true"> 
                    <img src={fiveImage} alt="fiveImage"/>
                </div>
            </div>
        </div>
    )
}

export default Five
