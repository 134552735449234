import React from 'react'


// PACKAGES


const AboutHero = ({ title, text }) => {
    return (
        <div className="about-hero">
            <div className="about-center">
                <h4>Nigeria's <span>#1</span> Currency Trading Company</h4>
                <h1>ABOUT US</h1>
                <p>We are known for delivering on our promises to our customers, and are recognized as a trusted brand and one of the world’s most admired companies.</p> 
            </div>
        </div>
    )
}

export default AboutHero
