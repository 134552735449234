import React, { useState } from 'react'

const TeamText = () => {
    const desc = "(introducing the Best Executive Team) Responsible for the company’s strategic direction, talent development and financial performance, our Executive Team is dedicated to building a company that nourishes the world in a safe, responsible and sustainable way. Although they represent a diverse collection of experience both inside and outside of Lighthill, each of them has a strong track record of leading people to achieve results. Together, they are working to make Lighthill the most trusted partner in Financial Solutions, Logistics , &amp; Agriculture produces."

    const [readMore, setReadMore] = useState(false);

    return (
        <div className="team-text">
            <h1 data-aos="fade-right" data-aos-once="true">Management Team</h1>
            <p>
                {
                    readMore ? desc : `${desc.substring(0, 100)}.....`
                }
            </p>
            <button onClick={() => setReadMore(!readMore)}>
                {readMore ? "show less" : "read more"}
            </button>
        </div>
    )
}

export default TeamText
