import React from 'react'

const Trusted = () => {
    return (
        <div className="trusted">
            <h2 data-aos="fade-down" data-aos-once="true">Trusted by over 5000 clients</h2>
            <p data-aos="fade-up" data-aos-once="true">We have been in successful operation for over 13 years now with outstanding results.</p>
        </div>
    )
}

export default Trusted