
const services = [

    {
        id: 1,
        header: 1,
        title: "make Business Strategy",
        text: `We will help you set new business priorities, outline plans for growth, determine a product roadmap or plan your investment decisions.`,
    },
    {
        id: 2,
        header: 2,
        title: "Company Of Professionals",
        text: `Competence, Reliability, Honesty, Integrity – these definitions characterize our employees in the best way.`,
    },
    {
        id: 3,
        header: 3,
        title: "We Love Our Clients",
        text: `Success of any organization depends on customers. That is why the first rule of our business is to retain clients and build a loyal relationship with them.`,
    },
]

export default services