import React from 'react'
import services from '../constants/Count'

const Count = () => {
    return (
        <div className="count_component">
            <div className="count-center">
                {
                    services.map(service => {
                        const { id, header, title, text } = service
                        return (
                            <div key={id} className="count-card">
                                <div className="count-header">
                                    <div className="count-left">
                                        <h2 data-aos="fade-right" data-aos-once="true"> {header}</h2>
                                    </div>

                                    <div className="count-right" data-aos="fade-up" data-aos-once="true">
                                        {title}
                                    </div>
                                </div>

                                <div className="count-footer" data-aos="zoom-in" data-aos-once="true">
                                    <p>{text}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Count
