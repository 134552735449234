import React from "react"

import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaFacebookSquare className="social-icon facebook"></FaFacebookSquare>,
    url: "https://web.facebook.com/lhgroup.lighthill",
  },
  {
    id: 2,
    icon: <FaLinkedin className="social-icon linkedin"></FaLinkedin>,
    url: "https://www.linkedin.com/company/lighthill-investment-company/about/",
  },
  {
    id: 4,
    icon: <FaInstagram className="social-icon instagram"></FaInstagram>,
    url: "https://www.instagram.com/lighthill_investment/",
  },
  {
    id: 5,
    icon: <FaTwitterSquare className="social-icon twitter"></FaTwitterSquare>,
    url: "http://www.twitter.com/lighthill",
  },
  {
    id: 6,
    icon: <FaEnvelope className="social-icon"></FaEnvelope>,
    url: "mailto:info@lighthillng.com",
  },
]


const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link" target="_blank" rel="noreferrer">
        {link.icon}
      </a>
    </li>
  )
})

const style = ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}

export default style