import React from 'react'
import Gallery from "react-grid-gallery";

const IMAGES = [
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/20258509_523115404686626_7357166187700225460_n_bgfuij.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/20258509_523115404686626_7357166187700225460_n_bgfuij.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        // caption: "After Rain (Jeshu John - designerspics.com)"
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149130/Lighthill/20294461_523115024686664_4355659397879933076_n_fwo5j5.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149130/Lighthill/20294461_523115024686664_4355659397879933076_n_fwo5j5.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // caption: "Boats (Jeshu John - designerspics.com)"
    },

    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/20375752_523115488019951_5026983586426610035_n_sltkhh.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/20375752_523115488019951_5026983586426610035_n_sltkhh.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149133/Lighthill/20375670_523115144686652_6592240668049165070_n_sjg3uh.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149133/Lighthill/20375670_523115144686652_6592240668049165070_n_sjg3uh.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        // caption: "After Rain (Jeshu John - designerspics.com)"
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149134/Lighthill/37425762_712387099092788_2009170209121763328_n_yanklo.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149134/Lighthill/37425762_712387099092788_2009170209121763328_n_yanklo.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // caption: "Boats (Jeshu John - designerspics.com)"
    },

    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149135/Lighthill/37912413_721728611491970_156616175722168320_n_lrzaz1.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149135/Lighthill/37912413_721728611491970_156616175722168320_n_lrzaz1.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149133/Lighthill/20258429_523115384686628_1748407544962052745_n_cfnctz.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149133/Lighthill/20258429_523115384686628_1748407544962052745_n_cfnctz.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        // caption: "After Rain (Jeshu John - designerspics.com)"
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149141/Lighthill/37948125_720458594952305_8519791294950670336_n_fbungs.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149141/Lighthill/37948125_720458594952305_8519791294950670336_n_fbungs.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // caption: "Boats (Jeshu John - designerspics.com)"
    },

    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149139/Lighthill/37905757_721728178158680_7575337469848059904_n_rnqyme.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149139/Lighthill/37905757_721728178158680_7575337469848059904_n_rnqyme.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149138/Lighthill/37947346_721729284825236_8477089304164696064_n_1_bje5uj.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149138/Lighthill/37947346_721729284825236_8477089304164696064_n_1_bje5uj.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        // caption: "After Rain (Jeshu John - designerspics.com)"
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/37855900_720453644952800_3675262450728435712_n_bzafle.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149132/Lighthill/37855900_720453644952800_3675262450728435712_n_bzafle.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        // caption: "Boats (Jeshu John - designerspics.com)"
    },

    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149142/Lighthill/37960501_721728704825294_2373042415419260928_n_j4ghfg.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149142/Lighthill/37960501_721728704825294_2373042415419260928_n_j4ghfg.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37932240_721728894825275_2828460647040155648_n_atr2jb.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37932240_721728894825275_2828460647040155648_n_atr2jb.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149142/Lighthill/37939412_720459124952252_9083106230397829120_n_lap2kt.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149142/Lighthill/37939412_720459124952252_9083106230397829120_n_lap2kt.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149141/Lighthill/37892018_721728274825337_7018546237536206848_n_pmjgl3.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149141/Lighthill/37892018_721728274825337_7018546237536206848_n_pmjgl3.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37991240_721729481491883_307697027658022912_n_l83twl.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37991240_721729481491883_307697027658022912_n_l83twl.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149146/Lighthill/37956511_721727524825412_7454581427779141632_n_d7t0mb.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149146/Lighthill/37956511_721727524825412_7454581427779141632_n_d7t0mb.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37932882_721729558158542_8626158032641851392_n_hdhusf.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37932882_721729558158542_8626158032641851392_n_hdhusf.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37947346_721729284825236_8477089304164696064_n_r5v8uy.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37947346_721729284825236_8477089304164696064_n_r5v8uy.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
    },

    
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149146/Lighthill/37956511_721727524825412_7454581427779141632_n_d7t0mb.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149146/Lighthill/37956511_721727524825412_7454581427779141632_n_d7t0mb.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37932882_721729558158542_8626158032641851392_n_hdhusf.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149144/Lighthill/37932882_721729558158542_8626158032641851392_n_hdhusf.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
    },
    {
        src: "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37947346_721729284825236_8477089304164696064_n_r5v8uy.jpg",
        thumbnail:
            "https://res.cloudinary.com/devsource/image/upload/v1622149143/Lighthill/37947346_721729284825236_8477089304164696064_n_r5v8uy.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 174,
    },
    // {
    //     src: "",
    //     thumbnail:
    //         "",
    //     thumbnailWidth: 320,
    //     thumbnailHeight: 174
    // },
    // {
    //     src: "",
    //     thumbnail:
    //         "",
    //     thumbnailWidth: 320,
    //     thumbnailHeight: 174
    // },
];

function Viewer() {
    return (
        <div className="viewer">
            <div className="viewer-center">
                <Gallery
                    images={IMAGES}
                    enableLightbox={true}
                    maxRows={10}
                    backdropClosesModal
                    showImageCount={true}
                    showLightboxThumbnails={true}
                // currentImage={3}
                // isOpen={ true}
                />
            </div>
        </div>
    );
}

export default Viewer