import React from 'react'

// PACKAGES
import { Link } from 'react-router-dom'

const Opportunity = () => {
    return (
        <div className="opportunity">
            <div className="opportunity-center">
                <h2 data-aos="fade-down" data-aos-once="true">Investment Opportunities</h2>
                <p data-aos="fade-up" data-aos-once="true">We strive to create long-term value for our stakeholders through strong business fundamentals, consistent with our mission guided by our vision and directed by our company’s core values.</p>
                <Link to="/contact">
                    <button data-aos="fade-down" data-aos-once="true">Reach Out</button>
                </Link>
            </div>
        </div>
    )
}

export default Opportunity
